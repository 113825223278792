/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

body {
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6, title {
    font-family: interstate, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.mblogo {
    font-family: interstate-condensed,sans-serif;
    font-weight: 800;
    font-style: normal;
}

.contactsuccess {
    color: purple;
    font-weight: 900;
    font-size: 300%;
    padding: 70px 2px;
}